import RoutingServices from "./config/RoutingServices";
import 'animate.css';

const ArterIndex = ({profile_id, color, username}) => {

    return(
        <>
            <RoutingServices username={username} profile_id={profile_id} color={color}/>
        </>
    )
}

export default ArterIndex;
