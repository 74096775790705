import {useSelector} from "react-redux";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {aws_url} from "../../../../config/Constant";

const PortfolioModel = () => {

    const {user_portfolio_model} = useSelector(state => state.user);

    const images = [];

    if(user_portfolio_model.main_image){
        images.push(aws_url+user_portfolio_model.main_image);
    }

    if(user_portfolio_model.extra_image_one){
        images.push(aws_url+user_portfolio_model.extra_image_one);
    }

    if(user_portfolio_model.extra_image_two){
        images.push(aws_url+user_portfolio_model.extra_image_two);
    }

    if(user_portfolio_model.extra_image_three){
        images.push(aws_url+user_portfolio_model.extra_image_three);
    }


    return (
        <>
            <div className="modal fade " id="PortfolioModel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content main_model">

                        <div className="modal-body">
                            <div>
                                {/*<div>*/}
                                {/*    <h2>{user_portfolio_model.title}</h2>*/}
                                {/*</div>*/}

                                <div className="model_data">
                                    <p className="model_heading"><b>{user_portfolio_model.type}</b></p>
                                    <p><small>{user_portfolio_model.start_date} - {user_portfolio_model.end_date}</small></p>
                                </div>

                                <div>

                                    <Slide>

                                        {images.length > 0 && images.map((data, index)=> {
                                            return <div className="each-slide-effect">
                                                <div style={{'backgroundImage': `url(${data})`}}>

                                                </div>
                                            </div>
                                        })}

                                    </Slide>
                                </div>

                                <div className="model_title">
                                    <h2>{user_portfolio_model.title}</h2>
                                </div>

                                {user_portfolio_model.skills &&
                                    <div className="mt-3">
                                        {JSON.parse(user_portfolio_model.skills).length > 0 && JSON.parse(user_portfolio_model.skills).map((data, index)=> {
                                            return <div className="skills_bages" style={{ padding: '3px 12px'}}><small>{data}</small></div>
                                        })}
                                    </div>
                                }


                                {user_portfolio_model.role &&
                                    <div>
                                        <div>
                                            <p className="mt-3">
                                                <span className="model_heading">Role : </span>
                                                <span>{user_portfolio_model.role}</span>
                                            </p>
                                        </div>
                                    </div>
                                }
                                <div className="model_data_list">
                                    {user_portfolio_model.description &&
                                        <div>
                                            <h4 className="model_heading">Description :</h4>

                                            <div className="ml-2">
                                                <p><small>{user_portfolio_model.description}</small></p>
                                            </div>
                                        </div>
                                    }


                                    {user_portfolio_model.solution &&
                                        <div>
                                            <h4 className="model_heading">Solution :</h4>

                                            <div className="ml-2">
                                                <p><small>{user_portfolio_model.solution}</small></p>
                                            </div>
                                        </div>
                                    }

                                    {user_portfolio_model.solution &&
                                        <div>
                                            <h4 className="model_heading">Tasks : </h4>

                                            <div className="ml-2">
                                                <p><small>{user_portfolio_model.task}</small></p>
                                            </div>

                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        {/*<div className="modal-footer">*/}
                        {/*    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioModel;
