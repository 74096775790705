import React, { useEffect, useState } from 'react';
import { ref, set, remove, runTransaction } from 'firebase/database';
import { database } from './firebaseConfig';
import { v4 as uuidv4 } from 'uuid';

const ActiveUserTracker = () => {
    let uniqueKey_ = '';
    let user_if = localStorage.getItem('active_user');
    let profile_user = localStorage.getItem('profile_user');
    let profile_slug = localStorage.getItem('profile_slug'); // Portfolio name

    if (user_if) {
        uniqueKey_ = user_if;
    } else {
        const uniqueKey = uuidv4();
        localStorage.setItem('active_user', uniqueKey);
        uniqueKey_ = uniqueKey;
    }

    const [userId, setUserId] = useState(uniqueKey_);
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setUserDetails((prev) => ({ ...prev, ipAddress: data.ip }));
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIP();
    }, []);

    useEffect(() => {
        if (!userDetails.ipAddress || !profile_slug) return; // Ensure IP and portfolio are set

        const activeRef = ref(database, `activeUsers/${userId}`);
        const portfolioRef = ref(database, `dailyActiveUsers/${profile_slug}`);

        const updateUserDetails = () => {
            const pageUrl = window.location.href;
            const timestamp = new Date().toISOString();
            const user_name = profile_user;

            set(activeRef, {
                ipAddress: userDetails.ipAddress,
                pageUrl,
                lastActive: timestamp,
                username: user_name,
                portfolio: profile_slug,
            });

            incrementDailyUserCount(portfolioRef, userId);
        };

        const handleActivity = () => {
            runTransaction(activeRef, (currentData) => {
                let pageUrl = window.location.href;
                if (currentData) {
                    currentData.lastActive = new Date().toISOString(); // Update last active time
                    return currentData;
                } else {
                    return {
                        ipAddress: userDetails.ipAddress,
                        pageUrl,
                        lastActive: new Date().toISOString(),
                    };
                }
            });
        };

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);

        const intervalId = setInterval(updateUserDetails, 5000);

        const cleanupUser = () => {
            remove(activeRef);
        };

        window.addEventListener('beforeunload', cleanupUser);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            window.removeEventListener('beforeunload', cleanupUser);
            clearInterval(intervalId);
            cleanupUser();
        };
    }, [userDetails, userId, profile_slug]);

    const getCurrentDate = () => {
        const now = new Date();
        return now.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    };

    const incrementDailyUserCount = (portfolioRef, userId) => {
        const today = getCurrentDate();

        runTransaction(portfolioRef, (currentData) => {
            if (!currentData) {
                currentData = { portfolio: profile_slug, dates: [] };
            }

            const existingDateEntry = currentData.dates.find((entry) => entry.date === today);

            if (existingDateEntry) {
                // Ensure the user isn't counted twice for today
                if (!existingDateEntry.users) existingDateEntry.users = {};
                if (!existingDateEntry.users[userId]) {
                    existingDateEntry.count += 1;
                    existingDateEntry.users[userId] = true;
                }
            } else {
                // Add a new entry for today
                currentData.dates.push({
                    date: today,
                    count: 1,
                    users: { [userId]: true }, // Track users to avoid duplicate counts
                });
            }

            return currentData;
        });
    };

    return null;
};

export default ActiveUserTracker;
