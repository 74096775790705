import React, { useState } from 'react';

const ContactForm = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
    });

    const validateField = (name, value) => {
        switch (name) {
            case 'name':
                return value ? '' : 'The field is required.';
            case 'email':
                // Basic email validation regex
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Please enter a valid email address.';
            case 'message':
                return value ? '' : 'The field is required.';
            default:
                return '';
        }
    };

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: validateField(name, value),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {
            name: validateField('name', formData.name),
            email: validateField('email', formData.email),
            message: validateField('message', formData.message),
        };

        setErrors(newErrors);

        const isValid = !Object.values(newErrors).some(error => error);

        if (isValid) {

            setFormData({
                name: '',
                email: '',
                message: '',
            });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="art-contact-form">
            <div className="art-form-field">
                <span className="wpcf7-form-control-wrap" data-name="your-name">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="wpcf7-form-control wpcf7-text art-input"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <span className="wpcf7-not-valid-tip"><small>{errors.name}</small></span>}
                </span>
                <label className="">
                    <i className="fas fa-user"></i>
                </label>
            </div>

            <div className="art-form-field">
                <span className="wpcf7-form-control-wrap" data-name="your-email">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="wpcf7-form-control wpcf7-text art-input"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <span className="wpcf7-not-valid-tip"><small>{errors.email}</small></span>}
                </span>
                <label className="">
                    <i className="fas fa-at"></i>
                </label>
            </div>

            <div className="art-form-field">
                <span className="wpcf7-form-control-wrap" data-name="your-message">
                    <textarea
                        name="message"
                        cols="40"
                        rows="10"
                        className="wpcf7-form-control wpcf7-textarea art-input"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    {errors.message && <span className="wpcf7-not-valid-tip"><small>{errors.message}</small></span>}
                </span>
                <label className="">
                    <i className="far fa-envelope"></i>
                </label>
            </div>
            <div className="art-submit-frame">
                <button className="art-btn art-btn-md art-submit" type="submit">
                    <span>Send message</span>
                </button>
            </div>
        </form>
    );
};

export default ContactForm;
