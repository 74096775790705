import Recommendations from "./Recommendations";
import Services from "./Services";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ReactHelmet from "../../../../utility/ReactHelmet";
import {s3_Link} from "../../../../config/Constant";
import CountUp from "react-countup";
import {TypeAnimation} from "react-type-animation";
const Home = () => {

    const {portfolio_details, banner_content} = useSelector(state => state.user);

    const {showService} = useSelector(state => state.user.showContentStatus);

    const [user_experience_details, setUserExperience] = useState({});

    useEffect(()=>{
        if(portfolio_details.length > 0){
            setUserExperience(portfolio_details[0]);
        }
    },[portfolio_details]);


    return(
        <>
            <ReactHelmet page_title="Home"/>
            <div className="page_section_main_div ">
                <div className="row">
                    <div className="col-md-12 pb-4 pt-3">


                        {banner_content?.showHomeBanner === 1 &&
                            <div>
                                <div className="main_slider_section">
                                    <div className="slider_avatar_main_div">
                                        <div className="p-2 mt-3">
                                            <h1>{banner_content?.details?.title}</h1>
                                            <p><b className="code_view">&lt;code&gt;</b>
                                                {/*<span className="typewriter_container">*/}
                                                {/*typewriter*/}
                                                <span
                                                    className="user_working_tag ">
                                                    <TypeAnimation
                                                        sequence={[
                                                            banner_content?.details?.code_title
                                                        ]}
                                                        speed={50}
                                                    />
                                                </span>
                                                {/*</span>*/}
                                                <b className="code_view">&lt;code&gt;</b></p>

                                            <a className="btn btn-style">Explore now</a>
                                        </div>

                                        <div>
                                            {banner_content?.avatar_image &&
                                                <img src={s3_Link + banner_content?.avatar_image} alt=""/>
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>
                        }


                        <div className="col-md-12 p-5 mt-4">
                            <div className="row mt-3 mb-3 pt-3">
                                <div className="col-md-3 col-6">
                                    <div className="art-counter-frame">
                                        <div className="art-counter-box">
                                      <span className="art-counter">
                                          <CountUp duration={5} className="counter"
                                                   end={user_experience_details?.total_experience}/>
                                      </span>
                                            <span className="art-counter-plus">+</span>
                                        </div>
                                        <h6 className="art-counter-text">
                                            <span>Years Experience</span>
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6">
                                    <div className="art-counter-frame">
                                        <div className="art-counter-box">
                                      <span className="art-counter">
                                          <CountUp duration={5} className="counter"
                                                   end={user_experience_details?.completed_projects}/>
                                      </span>
                                        </div>
                                        <h6 className="art-counter-text">
                                            <span>Completed Projects</span>
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6">
                                    <div className="art-counter-frame">
                                        <div className="art-counter-box">
                                      <span className="art-counter">
                                          <CountUp duration={5} className="counter"
                                                   end={user_experience_details?.happy_customers}/>
                                      </span>
                                        </div>
                                        <h6 className="art-counter-text">
                                            <span>Happy Customers</span>
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6">
                                    <div className="art-counter-frame">
                                        <div className="art-counter-box">
                                      <span className="art-counter">
                                          <CountUp duration={5} className="counter"
                                                   end={user_experience_details?.honors_and_awards}/>
                                      </span>
                                            <span className="art-counter-plus">+</span>
                                        </div>
                                        <h6 className="art-counter-text">
                                            <span>Honors and Awards</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {showService === true &&
                            <Services/>
                        }

                        {/*<Recommendations/>*/}

                    </div>
                </div>
            </div>

        </>
    )
}
export default Home;
