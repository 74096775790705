import Index from "./themes/Netgon/Index";
import TestIndex from "./themes/Test/Index";
import ArterIndex from "./themes/Arter/Index";
import {useEffect, useState} from "react";
import {Auth_POST_Service} from "./services/API_Services";
import {GetUserDetails, GetUserTheme} from "./config/EndPoints";
import NetgonIndex from "./themes/Netgon/Index";
import {UpdateState} from "./themes/Arter/utility/Utility";
import {setUserDetails, updateUserContentStatus} from "./features/userSlice";
import {useDispatch} from "react-redux";
import ActiveUserTracker from "./services/ActiveUserTracker";

const Main = () => {

    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const pathname = window.location.pathname;

    const [theme_details, setTheme_details] = useState({
        'template_id':0,
        'color':'',
        'profile_id':0,
        'username':null,
    });


    useEffect(()=>{

        const parts = pathname.split('/');

        if (parts.length > 1) {
            let user_details = parts[1];
            if(user_details){
                let data = {
                    'path':user_details
                }
                Auth_POST_Service(GetUserTheme, data).then(res=>{
                    if(res.status === 200){
                        let details = res.data.data;
                        let profile_details = details[0];

                        localStorage.setItem('profile_slug',profile_details.slug);

                        setTheme_details({
                            'template_id':profile_details.template_code,
                            'color':profile_details.color,
                            'profile_id':profile_details.profile_id,
                            'username':profile_details.username,
                        })

                        localStorage.setItem('profile_user', profile_details.username);
                        localStorage.setItem('theme_color', profile_details.color);
                    };

                    if(res.status === 404){

                    }
                })
                setUsername(parts[1]);

            }

        }

    },[pathname])





    useEffect(() => {
        if(theme_details.profile_id){
            let data = {
                'template_id':theme_details.profile_id
            }
            Auth_POST_Service(GetUserDetails, data).then(res=>{
                if(res.status === 200){
                    let details = res.data.message[0];


                    // showCodingSkills
                    //
                    // showHobbies
                    //
                    //
                    // showKnowledge
                    // showLanguage
                    // showPortfolio
                    //
                    // showSoftSkills
                    // showTechnicalSkills

                    const showExperience = JSON.parse(details.showExperience);
                    const showEducation = JSON.parse(details.showEducation);
                    const showAchievement = JSON.parse(details.showAchievement);
                    const showCertificate = JSON.parse(details.showCertificate);
                    const showReference = JSON.parse(details.showReference);
                    const showInternship = JSON.parse(details.showInternship);
                    const showCourse = JSON.parse(details.showCourse);
                    const showAbilities = JSON.parse(details.showAbilities);
                    const showInterest = JSON.parse(details.showInterest);
                    const showSoftSkills = JSON.parse(details.showSoftSkills);
                    const showLanguage = JSON.parse(details.showLanguage);
                    const showCodingSkills = JSON.parse(details.showCodingSkills);
                    const showTechnicalSkills = JSON.parse(details.showTechnicalSkills);
                    const showHobbies = JSON.parse(details.showHobbies);
                    const showService = JSON.parse(details.showService);
                    const showPortfolioSection = JSON.parse(details.showPortfolio);

                    const showKnowledge = JSON.parse(details.showKnowledge);

                    dispatch(updateUserContentStatus({
                        showExperience,
                        showEducation,
                        showAchievement,
                        showCertificate,
                        showReference,
                        showInternship,
                        showCourse,
                        showAbilities,
                        showInterest,
                        showSoftSkills,
                        showLanguage,
                        showCodingSkills,
                        showKnowledge,
                        showTechnicalSkills,
                        showHobbies,
                        showService,
                        showPortfolioSection,
                    }));

                    // let profile_details = details[0];
                    let user_knowledge = JSON.parse(details.user_knowledge) ? JSON.parse(details.user_knowledge) : [];
                    let user_services = JSON.parse(details.user_services) ? JSON.parse(details.user_services) : [];
                    let user_experience = JSON.parse(details.user_experience) ? JSON.parse(details.user_experience) : [];
                    let user_education = JSON.parse(details.user_education) ? JSON.parse(details.user_education) : [];
                    let user_soft_skills = JSON.parse(details.user_soft_skills) ? JSON.parse(details.user_soft_skills) : [];

                    let userAboutProfileDetails = JSON.parse(details.userAboutProfileDetails) ? JSON.parse(details.userAboutProfileDetails) : [];
                    let address = JSON.parse(details.address) ? JSON.parse(details.address) : [];
                    let user_coding_skills = JSON.parse(details.user_coding_skills) ? JSON.parse(details.user_coding_skills) : [];
                    let user_language = JSON.parse(details.user_language) ? JSON.parse(details.user_language) : [];
                    let user_achievements = JSON.parse(details.user_achievements) ? JSON.parse(details.user_achievements) : [];
                    let user_certificates = JSON.parse(details.user_certificates) ? JSON.parse(details.user_certificates) : [];
                    let user_reference = JSON.parse(details.user_reference) ? JSON.parse(details.user_reference) : [];
                    let user_portfolio = JSON.parse(details.user_portfolio) ? JSON.parse(details.user_portfolio) : [];
                    let socialLinks = JSON.parse(details.socialLinks) ? JSON.parse(details.socialLinks) : [];
                    let user_internships = JSON.parse(details.user_internships) ? JSON.parse(details.user_internships) : [];
                    let user_courses = JSON.parse(details.user_courses) ? JSON.parse(details.user_courses) : [];
                    let user_interest = JSON.parse(details.user_interest) ? JSON.parse(details.user_interest) : [];
                    let user_technical_skills = JSON.parse(details.user_technical_skills) ? JSON.parse(details.user_technical_skills) : [];
                    let user_hobbies = JSON.parse(details.user_hobbies) ? JSON.parse(details.user_hobbies) : [];
                    let portfolio_details = JSON.parse(details.portfolio_details) ? JSON.parse(details.portfolio_details) : [];
                    let banner_content = {
                        'details':JSON.parse(details.banner_content) ? JSON.parse(details.banner_content) : [],
                        'avatar_image' : details.avatar_image,
                        'showHomeBanner' : details.showHomeBanner
                    };


                    dispatch(setUserDetails({
                        user_knowledge,
                        user_services,
                        user_experience,
                        user_education,
                        user_soft_skills,
                        userAboutProfileDetails,
                        address,
                        user_coding_skills,
                        user_language,
                        user_achievements,
                        user_certificates,
                        user_reference,
                        user_portfolio,
                        socialLinks,
                        user_internships,
                        user_courses,
                        user_interest,
                        user_technical_skills,
                        user_hobbies,
                        portfolio_details,
                        banner_content,
                    }))
                };

                if(res.status === 404){

                }

            })
        }
    }, [theme_details.profile_id]);


    let theme = theme_details.template_id;


    return(
        <>
            <ActiveUserTracker/>
            {theme === 'Arter' &&
                <ArterIndex  username={username} profile_id={theme_details.profile_id} color={theme_details.color}/>
            }

            {theme === 'Netgon' &&
                <NetgonIndex username={username} profile_id={theme_details.profile_id} color={theme_details.color}/>
            }

            {theme === 4 &&
                <TestIndex/>
            }



            {theme === 4 &&
                <Index/>
            }
        </>
    )
}

export default Main;
