import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo:{
    user:  "",
    id: "",
    email: "",
    first_name: "",
  },
  user_knowledge:[],
  user_services:[],
  user_experience:[],
  user_education:[],
  user_soft_skills:[],
  userAboutProfileDetails:[],
  address:[],
  user_coding_skills:[],
  user_language:[],
  user_achievements:[],
  user_certificates:[],
  user_internships:[],
  user_reference:[],
  user_portfolio:[],
  user_portfolio_model:[],
  socialLinks:[],
  user_courses:[],
    user_interest:[],
    user_technical_skills:[],
    user_hobbies:[],
    portfolio_details:[],
    banner_content:[],

  showContentStatus:{
      showExperience:false,
      showEducation:false,
      showAchievement:false,
      showCertificate:false,
      showReference:false,
      showInternship:false,
      showCourse:false,
      showAbilities:false,
      showInterest:false,
      showSoftSkills:false,
      showLanguage:false,
      showCodingSkills:false,
      showknowledge:false,
      showTechnicalSkills:false,
      showHobbies:false,
      showService:false,
      showPortfolioSection:false,
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {

    setUserDetails:(state,action)=>{
        state.user_knowledge = action.payload.user_knowledge;
        state.user_services = action.payload.user_services;
        state.user_experience = action.payload.user_experience;
        state.user_education = action.payload.user_education;
        state.user_soft_skills = action.payload.user_soft_skills;
        state.userAboutProfileDetails = action.payload.userAboutProfileDetails;
        state.address = action.payload.address;
        state.user_coding_skills = action.payload.user_coding_skills;
        state.user_language = action.payload.user_language;
        state.user_achievements = action.payload.user_achievements;
        state.user_certificates = action.payload.user_certificates;
        state.user_reference = action.payload.user_reference;
        state.user_portfolio = action.payload.user_portfolio;
        state.socialLinks = action.payload.socialLinks;
        state.user_internships = action.payload.user_internships;
        state.user_courses = action.payload.user_courses;
        state.user_interest = action.payload.user_interest;
        state.user_technical_skills = action.payload.user_technical_skills;
        state.user_hobbies = action.payload.user_hobbies;
        state.portfolio_details = action.payload.portfolio_details;
        state.banner_content = action.payload.banner_content;
    },



    setUserProfile:(state,action)=>{
      state.userInfo.id = action.payload.id;
    },

    setUserPortfolioModel:(state,action)=>{
      state.user_portfolio_model = action.payload;
    },
    userLogout:(state)=>{
      state=null;
    },


    updateUserProfileAvatar:(state,action)=>{
      state.userInfo.avatar   = action.payload.avatar;
    },


    updateUserContentStatus:(state,action)=>{
      state.showContentStatus.showExperience   = action.payload.showExperience;
      state.showContentStatus.showEducation   = action.payload.showEducation;
      state.showContentStatus.showAchievement   = action.payload.showAchievement;
      state.showContentStatus.showCertificate   = action.payload.showCertificate;
      state.showContentStatus.showReference   = action.payload.showReference;
      state.showContentStatus.showInternship   = action.payload.showInternship;
      state.showContentStatus.showCourse   = action.payload.showCourse;
      state.showContentStatus.showAbilities   = action.payload.showAbilities;
      state.showContentStatus.showInterest   = action.payload.showInterest;
      state.showContentStatus.showSoftSkills   = action.payload.showSoftSkills;
      state.showContentStatus.showLanguage   = action.payload.showLanguage;
      state.showContentStatus.showCodingSkills   = action.payload.showCodingSkills;
      state.showContentStatus.showKnowledge   = action.payload.showKnowledge;
      state.showContentStatus.showTechnicalSkills   = action.payload.showTechnicalSkills;
      state.showContentStatus.showHobbies   = action.payload.showHobbies;
      state.showContentStatus.showService   = action.payload.showService;
      state.showContentStatus.showPortfolioSection   = action.payload.showPortfolioSection;
    },


  },
});

export const { setUserProfile,updateUserContentStatus, userLogout,updateUserProfileAvatar, setUserDetails ,
    setUserPortfolioModel} = userSlice.actions;

export default userSlice.reducer;
