import {userKnowledge} from "../../services/UserSidebarServices";
import {useSelector} from "react-redux";
const Knowledge = () => {

  const {user_knowledge, showknowledge} = useSelector(state => state.user);

  return(
      <>
          {/*{showknowledge &&*/}
              <>
                  <div className="languages_div_title">
                      <h3>Knowledge</h3>
                  </div>
                  <div className="languages_div pb-2 mt-3">
                      <div>
                          <ul className="sidebar-knowledge-list">
                              {user_knowledge.map((knowledge,index)=>{
                                  return <li key={index}><i className="fas fa-check"></i>{knowledge.name}</li>
                              })}
                          </ul>
                      </div>
                  </div>
              </>

          {/*}*/}
      </>
  )
}
export default Knowledge;